<template>
  <div class="page-customer">
    <content-header :title="title" :breadList="['系统设置', '权限集']">
      <el-button slot="tools" type="primary" round @click="handleAdd">
        <i class="el-icon-plus"></i>
        <span>新增权限</span>
      </el-button>
    </content-header>
    <div class="content-wrapper">
      <el-table :data="tableData" v-loading="loading" class="table-customer">
        <el-table-column prop="Name" label="权限名称"> </el-table-column>
        <el-table-column prop="Description" label="描述"> </el-table-column>
        <el-table-column prop="IsCustom" label="自定义">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.IsCustom" disabled> </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="tool-wrap">
              <el-button type="text" @click="handleEdit(scope.row)">
                <i class="iconfont icon-edit"></i>
              </el-button>
              <el-dropdown
                @command="handleCommand($event, scope.row)"
                v-if="scope.row.IsCustom"
              >
                <span class="el-dropdown-link">
                  <i class="iconfont icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="delete"
                    ><i class="iconfont icon-delete"></i>删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curUser"
      @success="handleSaved"
    ></edit-drawer>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该权限集吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";
import { apiGetPermissionsList, apiDeletePermissions } from "@/api/auth";

export default {
  components: {
    EditDrawer,
    ContentHeader,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      tableData: [],
      existDrawer: false,
      delConfirmShow: false,
      editType: "add",
      curUser: null,
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
    };
  },
  methods: {
    getAuthList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
      };
      apiGetPermissionsList(param)
        .then((res) => {
          res.permissionSets.forEach((item) => {
            item.IsCustom = item.IsCustom == 1 ? true : false;
          });
          this.tableData = res.permissionSets;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleEdit(detail) {
      this.curUser = detail;
      this.editType = "edit";

      this.existDrawer = true;
    },
    handleAdd() {
      this.curUser = null;
      this.editType = "add";

      this.existDrawer = true;
    },
    handleCommand(cmd, detail) {
      switch (cmd) {
        case "detail":
          this.$router.push(`/group/detail/${detail._id}`);
          break;
        case "delete":
          this.curUser = detail;
          this.delConfirmShow = true;
          break;
      }
    },
    handleSaved() {
      this.existDrawer = false;
      this.getAuthList();
    },
    handleDelete() {
      apiDeletePermissions(this.curUser._id).then(() => {
        this.delConfirmShow = false;
        this.getAuthList();
      });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getAuthList();
    },
  },
  mounted() {
    this.getAuthList();
    if (this.$route.query.status === "add") {
      this.existDrawer = true;
      this.editType = "add";
    }
  },
};
</script>

<style lang="less" scoped>
.page-customer {
  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
}
</style>
