<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :size="1000"
      v-loading="loading"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{ saveType === "add" ? "新增权限" : "修改权限" }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="!formData.IsCustom"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="80px"
        >
          <cond-card>
            <el-form-item label="名称" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="请输入权限名称"
                style="width: 440px"
                :disabled="!formData.IsCustom"
              ></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="Description">
              <el-input
                v-model="formData.Description"
                placeholder="请输入权限描述"
                style="width: 440px"
                :disabled="!formData.IsCustom"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="源权限集"
              prop="PermissionSetId"
              v-if="saveType === 'add'"
            >
              <el-select
                v-model="formData.PermissionSetId"
                placeholder="请选择源权限集"
                style="width: 440px"
                @change="handlePermissionSetId"
              >
                <el-option
                  :value="item._id"
                  :label="item.Name"
                  v-for="item in permissionsList"
                  :key="item._id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="自定义" prop="IsCustom">
              <el-switch v-model="formData.IsCustom" disabled></el-switch>
            </el-form-item>
          </cond-card>
          <template v-if="saveType !== 'add' || formData.PermissionSetId">
            <cond-card :title="'管理权限'">
              <!-- <el-checkbox-group
              v-model="selAdminList"
              style="margin-bottom: -16px"
            > -->
              <el-row>
                <el-col
                  :span="12"
                  v-for="item in ManagerPermissions"
                  :key="item.label"
                >
                  <div class="permission-box">
                    <span class="label">{{ item.Name }}</span>
                    <el-checkbox
                      v-model="item.Value"
                      :true-label="1"
                      :false-label="0"
                      :disabled="!formData.IsCustom"
                    ></el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <!-- </el-checkbox-group> -->
            </cond-card>
            <cond-card :title="'普通用户权限'">
              <el-row>
                <el-col
                  :span="12"
                  v-for="item in GeneralPermissions"
                  :key="item.label"
                >
                  <div class="permission-box">
                    <span class="label">{{ item.Name }}</span>
                    <el-checkbox
                      v-model="item.Value"
                      :true-label="1"
                      :false-label="0"
                      :disabled="!formData.IsCustom"
                    ></el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </cond-card>
            <cond-card :title="'模块权限'">
              <el-table class="table-customer" :data="tableData">
                <el-table-column prop="Name" label="名称"> </el-table-column>
                <el-table-column prop="date" label="基础权限" width="310">
                  <template slot-scope="scope">
                    <div v-if="scope.$index == 0" class="table-header">
                      <span>读取</span>
                      <span>创建</span>
                      <span>编辑</span>
                      <span>删除</span>
                    </div>
                    <div class="table-base" v-else>
                      <div
                        class="table-base-checkbox"
                        @click="
                          handleChangeBasePermission(
                            item,
                            scope.$index,
                            baseIndex
                          )
                        "
                        v-for="(item, baseIndex) in scope.row.BasePermissions"
                        :key="item.APIName"
                      >
                        <el-checkbox
                          v-model="item.Value"
                          :true-label="1"
                          :false-label="0"
                          :disabled="!formData.IsCustom"
                        ></el-checkbox>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="date" label="数据管理" width="226">
                  <template slot-scope="scope">
                    <div v-if="scope.$index == 0" class="table-header">
                      <span>查看全部</span>
                      <span>修改全部</span>
                    </div>
                    <div v-else class="table-data-manage">
                      <div
                        class="table-data-manage-checkbox"
                        @click="
                          handleChangeDataPermission(
                            item,
                            scope.$index,
                            baseIndex
                          )
                        "
                        v-for="(item, baseIndex) in scope.row.DataPermissions"
                        :key="item.APIName"
                      >
                        <el-checkbox
                          :key="item.APIName"
                          v-model="item.Value"
                          :true-label="1"
                          :false-label="0"
                          :disabled="!formData.IsCustom"
                        ></el-checkbox>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </cond-card>
          </template>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { apiGetPermissionDetail, apiCreatePermissions } from "@/api/auth";
import condCard from "@/components/cond-card";
import { apiGetPermissionsList } from "@/api/auth";

export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Name: "",
        Description: "",
        IsCustom: true,
        PermissionSetId: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入权限名称",
          trigger: "blur",
        },
        Description: {
          required: true,
          message: "请输入权限描述",
          trigger: "blur",
        },
        PermissionSetId: {
          required: true,
          message: "请选择源权限集",
          trigger: "change",
        },
      },
      ManagerPermissions: [],
      GeneralPermissions: [],
      tableData: [{}],
      otherTableData: [],
      loading: false,
      permissionsList: [],
    };
  },
  computed: {},
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.getUserDetail();
      } else {
        this.resetForm();
      }
      this.getAuthList();
    },
  },
  methods: {
    handlePermissionSetId(val) {
      this.loading = true;
      apiGetPermissionDetail(val)
        .then((res) => {
          this.ManagerPermissions = res.ManagerPermissions;
          this.GeneralPermissions = res.GeneralPermissions;
          const { otherTableData, tableData } = this.getTableData(
            res.ObjectList
          );
          this.tableData = [{}];
          this.tableData = this.tableData.concat(tableData);
          this.otherTableData = otherTableData;
          this.$forceUpdate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.saving = true;
      let ObjectList = JSON.parse(JSON.stringify(this.tableData));
      ObjectList.shift();

      ObjectList = ObjectList.concat(this.otherTableData);
      const param = {
        ...this.formData,
        ManagerPermissions: this.ManagerPermissions,
        GeneralPermissions: this.GeneralPermissions,
        ObjectList,
      };
      param.IsCustom = param.IsCustom ? 1 : 0;
      let prom;
      if (this.saveType === "add") {
        prom = apiCreatePermissions(0, param);
      } else {
        prom = apiCreatePermissions(this.detail && this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.ManagerPermissions = [];
      this.GeneralPermissions = [];
      this.tableData = [{}];
      this.$refs.form.resetFields();
    },
    // 设置 tableData 和 otherTableData 值
    getTableData(ObjectList) {
      const otherTableData = [];
      const tableData = [];
      ObjectList.forEach((item) => {
        if (item.ObjectType === "Standard") {
          tableData.push(item);
        } else {
          otherTableData.push(item);
        }
      });
      return {
        otherTableData: JSON.parse(JSON.stringify(otherTableData)),
        tableData: JSON.parse(JSON.stringify(tableData)),
      };
    },
    // 获取详情
    getUserDetail() {
      this.loading = true;
      if (this.saveType === "edit" && this.detail) {
        apiGetPermissionDetail(this.detail._id)
          .then((res) => {
            Object.assign(this.formData, {
              Name: res.Name,
              Description: res.Description,
              IsCustom: res.IsCustom == 1 ? true : false,
            });

            this.ManagerPermissions = res.ManagerPermissions;
            this.GeneralPermissions = res.GeneralPermissions;
            const { otherTableData, tableData } = this.getTableData(
              res.ObjectList
            );
            this.tableData = [{}];
            this.otherTableData = [];
            this.tableData = this.tableData.concat(tableData);
            this.otherTableData = this.otherTableData.concat(otherTableData);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.getInitPermission();
      }
    },
    // 初始化权限选项
    getInitPermission() {
      apiGetPermissionDetail(0)
        .then((res) => {
          this.ManagerPermissions = res.ManagerPermissions;
          this.GeneralPermissions = res.GeneralPermissions;
          const { otherTableData, tableData } = this.getTableData(
            res.ObjectList
          );
          this.tableData = [{}];
          this.otherTableData = [];
          this.tableData = this.tableData.concat(tableData);
          this.otherTableData = this.otherTableData.concat(otherTableData);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 选择基础权限
    handleChangeBasePermission(item, index, baseIndex) {
      setTimeout(() => {
        //baseIndex 读取 0 ，创建1 ，编辑2 ，删除3
        // 选中
        if (item.Value == 1) {
          switch (baseIndex) {
            // 选中创建，勾选读取
            case 1:
              this.tableData[index].BasePermissions[0].Value = 1;
              break;
            // 选中编辑，勾选读取
            case 2:
              this.tableData[index].BasePermissions[0].Value = 1;
              break;
            // 选中删除，勾选读取、编辑
            case 3:
              this.tableData[index].BasePermissions[0].Value = 1;
              this.tableData[index].BasePermissions[2].Value = 1;
              break;
          }
        }
        // 取消
        else {
          switch (baseIndex) {
            // 如果取消勾选：读取，请自动取消勾选：创建、编辑、删除、查看全部、修改全部
            case 0:
              this.tableData[index].BasePermissions[1].Value = 0;
              this.tableData[index].BasePermissions[2].Value = 0;
              this.tableData[index].BasePermissions[3].Value = 0;
              this.tableData[index].DataPermissions[0].Value = 0;
              this.tableData[index].DataPermissions[1].Value = 0;
              break;
            // 如果取消勾选：编辑，请自动取消勾选：删除、修改全部
            case 2:
              this.tableData[index].BasePermissions[3].Value = 0;
              this.tableData[index].DataPermissions[1].Value = 0;
              break;
            // 如果取消勾选：删除，请自动取消勾选：修改全部
            case 3:
              this.tableData[index].DataPermissions[1].Value = 0;
              break;
          }
        }
      }, 50);
    },
    // 选择数据管理
    handleChangeDataPermission(item, index, baseIndex) {
      setTimeout(() => {
        // baseIndex 查看全部 0，修改全部 1
        // 选中
        if (item.Value === 1) {
          switch (baseIndex) {
            // 选中查看全部，自动勾选读取
            case 0:
              this.tableData[index].BasePermissions[0].Value = 1;
              break;
            // 选中修改全部，请自动勾选：读取、编辑、删除、查看全部
            case 1:
              this.tableData[index].BasePermissions[0].Value = 1;
              this.tableData[index].BasePermissions[2].Value = 1;
              this.tableData[index].BasePermissions[3].Value = 1;
              this.tableData[index].DataPermissions[0].Value = 1;
              break;
          }
        }
      }, 50);
    },
    getAuthList() {
      const param = {
        offset: 0,
        limit: 10000,
      };
      apiGetPermissionsList(param)
        .then((res) => {
          this.permissionsList = res.permissionSets;
        })
        .finally(() => {});
    },
  },
  created() {
    this.getAuthList();
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    // width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
  .permission-box {
    margin-bottom: 26px;
    .label {
      font-size: 12px;
      color: #747378;
      width: 116px;
      margin-right: 10px;
      display: inline-block;
    }
  }
  .table-header {
    display: flex;

    span {
      flex: 1;
      font-size: 13px;
      color: #000000;
      display: inline-block;
    }
  }
  .table-base {
    display: flex;
    &-checkbox {
      flex: 1;
      padding-left: 6px;
      margin: 0;
    }
  }
  .table-data-manage {
    display: flex;
    &-checkbox {
      flex: 1;
      padding-left: 18px;
      margin: 0;
    }
  }
}
</style>
